<template>
  <!-- Компонент для отрисовки Списка Отделений -->
  <div class="departments-list__container">

    <!-- уведомление об ошибке -->
    <error-note-comp>{{ errorMsg }}</error-note-comp>

    <!-- Уведомление об успехе -->
    <success-note-comp @complete="handlerCompleteAnimation"></success-note-comp>

    <!-- Заголовок -->
    <div class="departments-list__header">
      <v-icon @click="router.go(-1)" class="btn-back" icon="mdi-arrow-left" size="22"></v-icon>
      <h1 class="departments-list__header--title">Отделения</h1>
    </div>

    <!-- Диалоговое окно с данными о выбранном элементе -->
    <departmentsDialogComp v-model="isShowDialog" @error="(e) => handlerError(e)" @success="handlerSuccess"
      @close="handlerCloseDialogInfo"
      @load-department="(department: DepartmentClient) => emit('loadDepartment', department)"
      @update-department="(department: DepartmentClient) => emit('updateDepartment', department)" />

    <!-- Диалоговое окно для создания нового отделения -->
    <creationDepartmentsComp v-model="isShowCreationDialog" @error="(e) => handlerError(e)" @success="handlerSuccess"
      @close="isShowCreationDialog = false" @append-department="(department) => emit('loadDepartment', department)" />

    <!-- Диалоговое окно для подтверждения удаления элемента -->
    <disableDepartmentDialogComp v-model="isShowDisabledDialog" @cancel="isShowDisabledDialog = false"
      @confirm="confirmDisabledDepartment" :is-loading="isLoadingDisabled" :title="`${disabledDepartmentData.name}`" />

    <!-- Диалоговое окно для подтверждения Активации элемента -->
    <activeDepartmentDialogComp v-model="isShowActivateDialog" :department-name="activatedDepartmentData.name"
      :is-loading="isLoadingActivated" :title="`${disabledDepartmentData.name}`" @confirm="confirmActivateDepartment"
      @cancel="isShowActivateDialog = false" />

    <!-- Панель действий -->
    <div class="departments-list__actions-block">
      <v-btn style="color: var(--color-white); font-size: 0.8em; font-weight: bold;" color="var(--color-default)"
        rounded="large" size="large" @click="isShowCreationDialog = true">
        Создать
      </v-btn>

      <!-- Фильтр панель -->
      <departmentsFilterPanelComp @select-status="(status: boolean) => filledFilterData(null, status)"
        @select-hospital-name="(hospitalName: string | null) => filledFilterData(hospitalName, isDeleted)"
        :isActiveHospital="props.isActiveHospital" :list-hospital-names="listHospitalNames" />
    </div>

    <!-- Контент список -->
    <div class="departments-list__content-container" v-if="!isLoadDepartments">

      <!-- Подсказка указывает на отсутствие элементов в списке -->
      <div class="is-not-items-hint" v-if="!props.departments.length">
        <h1>Отделений не найдено</h1>
      </div>

      <!-- Отрисовка элеменитов списка -->
      <v-table class="departments-list__wrapper" :density="'comfortable'" fixed-header>
        <thead>
          <tr>
            <th class="text-left th avatar-department">
            </th>
            <!-- Название -->
            <th class="text-left th">
              Название
            </th>
            <!-- Организация -->
            <th class="text-left th">
              Организация
            </th>
            <!-- Статус -->
            <th class="text-left th">
              Статус
            </th>
            <!-- Действия -->
            <th class="text-left th actions-th">
              Действия
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="row-hover" @click="() => routeToHospitalRooms(department?.id, department?.hospital.id)"
            v-for="(department) in props.departments as ArrayDepartmentClient" :key="department.id">
            <!-- № Номер -->
            <td class="list-item">
              <v-icon icon="mdi-table" color="var(--color-default)"></v-icon>
            </td>
            <!-- Название -->
            <td class="list-item department-name">{{ department?.name }}</td>
            <!-- Организация -->
            <td class="list-item">{{ department?.hospital.name }}</td>
            <!-- Статус -->
            <td class="list-item">{{ (props.isDeleted) ? 'Не активен' : 'Активен' }}</td>
            <!-- ДЕЙСТВИЯ -->
            <td class="list-item actions-td">

              <!-- Редактировать -->
              <v-btn class="actions__btn" @click.stop="selectDepartment(department?.id)" rounded="xs"
                icon="mdi-cog-outline" :color="'var(--color-gray)'" variant="text" :density="'comfortable'">
              </v-btn>

              <!-- сделать НЕАКТИВНЫМ / АКТИВНЫМ -->
              <v-btn class="actions__btn del"
                @click.stop="(props.isDeleted === true) ? handlerActivatedDepartment(department?.id, department?.name) : handlerDisabledDepartment(department?.id, department?.name)"
                rounded="xs" variant="text" :density="'comfortable'" icon="mdi-power"
                :color="(props.isDeleted === true) ? 'green' : 'red'">
              </v-btn>
            </td>
          </tr>
          <!-- Триггерный блок для пагинации -->
          <tr class="last-item">
            <td class="trigger-pagination">
              <v-progress-circular v-show="isShowLoadingNewPage" indeterminate bg-color="var(--bg-color-white)"
                color="var(--bg-color-default)" :size="20" :width="2"></v-progress-circular>
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>

    <!-- Крутилка загрузки -->
    <span class="progress-circular" v-else>
      <v-progress-circular indeterminate bg-color="var(--bg-color-white)" color="var(--bg-color-default)" :size="60"
        :width="6"></v-progress-circular>
    </span>
  </div>
</template>

<script setup lang="ts">
// COMPONENTS
import departmentsDialogComp from './departmentsDialogComp.vue';
import creationDepartmentsComp from './creationDepartmentsComp.vue';
import disableDepartmentDialogComp from './disableDepartmentDialogComp.vue';
import activeDepartmentDialogComp from './activeDepartmentDialogComp.vue';
import departmentsFilterPanelComp from './departmentsFilterPanelComp.vue';
// STORE
import useMainStore from '../../store/mainStore';
import useAuthStore from '../../store/authStore';
// API
import { putStatusDepartmentById } from '../../api/superuser/departmentsApi';
import { putStatusDepartmentById__chief } from '../../api/hospitalChief/departmentsApi';
// TYPES
import { DepartmentClient, ArrayDepartmentClient } from '../../types/departmentType';
// VUE
import { ref, defineProps, onMounted, watch, defineEmits, nextTick, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const store = useMainStore();
const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

// ================================   PROPS   ==================================
const props = defineProps<{
  departments: Array<any>;
  isLoadDepartments: boolean;
  isDeleted: boolean;
  isActiveHospital: boolean;
}>();


// ================================   EMIT   ==================================
const emit = defineEmits<{
  loadDepartment: [department: DepartmentClient],
  updateDepartment: [department: DepartmentClient],
  paginationUp: [];
  departmentFilter: [filterData: { hospitalName: string | null, isDeleted: boolean }];
}>();

// ===============================================   DATA   ==============================================
const errorMsg = ref('');
const isShowDialog = ref<boolean>(false);
const isShowCreationDialog = ref<boolean>(false);
const isShowDisabledDialog = ref<boolean>(false);
const isShowActivateDialog = ref<boolean>(false);
const isShowLoadingNewPage = ref<boolean>(false);
const isLoadingDisabled = ref<boolean>(false);
const isLoadingActivated = ref<boolean>(false);
const disabledDepartmentData = ref({
  id: null as number | null,
  name: '',
});
const activatedDepartmentData = ref({
  id: null as number | null,
  name: '',
});
const filterData = ref({
  hospitalName: undefined as string | undefined,
  isDeleted: false as boolean,
})

// Наблюдатель для триггера подгрузки страниц пагинации списка
const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (store.isDepartmentPaginationHasNext === true && props.departments.length >= 30) {
        isShowLoadingNewPage.value = true;
      } else isShowLoadingNewPage.value = false;
      // Событие paginationUp для подгрузки данных следующей страницы пагинации
      emit('paginationUp');
    }
  });
});

// ===============================================   METHODS   ===============================================
// Обновление статуса активности отделения
async function putStatusDepartmentByIdGeneral(departmentId: number, deleted: boolean) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await putStatusDepartmentById(departmentId, deleted);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await putStatusDepartmentById__chief(departmentId, deleted);
  } catch (err) {
    throw new Error(`components/departments/departmentsListComp: putStatusDepartmentByIdGeneral  => ${err}`);
  }
}

// функция для открытия окна информации отделения или окна деактивации отделения
function selectDepartment(departmentId: number) {
  isShowDialog.value = true;
  store.toPushWithQuery({ name: 'select-department', value: departmentId }, route, router);
  // router.push({ query: { 'select-department': departmentId } });
}

// Заполняет объект фильтр данных
function filledFilterData(hospitalName: string | null, isDeleted: boolean | null) {
  try {
    // hospitalName
    if (hospitalName !== null) {
      if (hospitalName === '') {
        filterData.value.hospitalName = undefined;
      } else filterData.value.hospitalName = hospitalName;
    }
    if (isDeleted !== null) {
      filterData.value.isDeleted = isDeleted;
    }
    emit('departmentFilter', filterData.value);
  } catch (err) {
    throw new Error(`components/departments/departmentsListComp: filledFilterData  => ${err}`);
  }
}

// Обработчик перехода на маршрут палат выбранного отделения
function routeToHospitalRooms(departmentId: number, hospitalId: number) {
  try {
    router.push({ name: 'hospitalRooms', query: { 'hospital': hospitalId, 'department': departmentId } });
  } catch (err) {
    throw new Error(`components/departments/departmentsListComp: routeToHospitalRooms  => ${err}`);
  }
}

// отображение уведомления об успехе какой-либо операции
function handlerSuccess() {
  store.activeSuccessOperation(1200);
}

// отображение уведомления об ошибке при выполнении какой-либо операции
function handlerError(error: string) {
  errorMsg.value = error;
  store.activeErrorOperation(3500);
}

// Обработчик выполнения анимации уведомления об успехе
function handlerCompleteAnimation() {
  if (isShowCreationDialog.value === true) {
    isShowCreationDialog.value = false;
    window.location.reload();
  }
  if (isShowDialog.value === true) {
    isShowDialog.value = false;
  }
}

// Обработчик открытия окна деактивации отделения
function handlerDisabledDepartment(departmentId: number, departmentName: string | null) {
  try {
    if (departmentName) {
      disabledDepartmentData.value.id = departmentId;
      disabledDepartmentData.value.name = departmentName;
      isShowDisabledDialog.value = true;
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsListComp: handlerDisabledDepartment  => ${err}`);
  }
}

// Обработчик открытия окна АКТИВАЦИИ отделения
function handlerActivatedDepartment(departmentId: number, departmentName: string | null) {
  try {
    if (departmentName) {
      activatedDepartmentData.value.id = departmentId;
      activatedDepartmentData.value.name = departmentName;
      isShowActivateDialog.value = true;
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsListComp: handlerDisabledDepartment  => ${err}`);
  }
}

// Подтверждение деактивации Отделения
async function confirmDisabledDepartment() {
  try {
    isLoadingDisabled.value = true;
    if (disabledDepartmentData.value.id) {
      store.activeSuccessOperation(900);
      await putStatusDepartmentByIdGeneral(disabledDepartmentData.value.id, true);
      window.location.reload();
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsListComp: confirmDisabledDepartment  => ${err}`);
  } finally {
    isLoadingDisabled.value = false;
  }
}

// Подтверждение АКТИВАЦИИ Отделения
async function confirmActivateDepartment() {
  try {
    isLoadingActivated.value = true;
    if (activatedDepartmentData.value.id) {
      store.activeSuccessOperation(900);
      await putStatusDepartmentByIdGeneral(activatedDepartmentData.value.id, false);
      window.location.reload();
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsListComp: confirmActivateDepartment  => ${err}`);
  } finally {
    isLoadingActivated.value = false;
  }
}

// Обработчик закрытия окна информации о отделении 
function handlerCloseDialogInfo() {
  try {
    store.toPopQuery('select-department', route, router);
    isShowDialog.value = false;
  } catch (err) {
    throw new Error(`components/departments/departmentsListComp.vue: handlerCloseDialogInfo  => ${err}`)
  }
}

// ===============================================   COMPUTED   ===============================================
// Вычисление списка Названий Больниц для фильтр-панели
const listHospitalNames = computed<Array<string | undefined>>(() => {
  try {
    let names = props.departments.map((department: DepartmentClient) => {
      if (department.hospital.name !== null && department.hospital.name !== undefined) {
        return department.hospital.name;
      }
    });

    return [...new Set(names)];
  } catch (err) {
    throw new Error(`components/departments/departmentsListComp: computed[listHospitalNames]  => ${err}`);
  }
});


// ===============================================   WATCH   ===============================================
// Отслеживание достижения тригеррного блока для смены страницы пагинации
watch(() => props.departments.length, async (newLength) => {
  if (newLength > 0) {
    await nextTick();
    // получение последнего элемента списка
    const triggerPagination = document.querySelector('.trigger-pagination') as HTMLDivElement;
    if (triggerPagination) {
      observer.observe(triggerPagination);  // запуск observer
    }
  }
});

// Удаляем query-параметр если диалоговое окно просмотра закрывается
watch(() => isShowDialog.value, (isShow) => {
  if (isShow === false) {
    handlerCloseDialogInfo();
  }
})

// ===============================================   LIFECYCLE HOOKS   ===============================================
onMounted(() => {
  // При монтировании списка больниц проверям существует ли query-параметр select-hospital
  try {
    const departmentIdQuery = route.query['select-department'];
    if (departmentIdQuery) {
      if (typeof departmentIdQuery === 'string' && +departmentIdQuery > 0) {
        // Если он существует то открывается окно просмотра этой больницы
        selectDepartment(+departmentIdQuery);
      }
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsListComp.vue: onMounted  => ${err}`);
  }
});

</script>

<style scoped>
.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
}

.row-hover {
  transition: background-color 0.4s ease;
  cursor: pointer;
}

.row-hover:hover {
  background-color: rgba(128, 128, 128, 0.2);
  transition: background-color 0.4s ease;
}

.departments-list__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--bg-color-white);
  overflow: hidden;
}

.is-not-items-hint {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--color-default);
  z-index: 25;
}

.departments-list__header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  background-color: var(--bg-color-default);
  color: var(--color-white);
  border-bottom: var(--border-thin);
  z-index: 20;
}

.btn-back {
  position: absolute;
  left: 1rem;
  cursor: pointer;
}

.btn-back:hover {
  color: rgba(255, 255, 255, 0.519);
}

.departments-list__header--title {
  font-family: var(--font);
  font-size: 1.25rem;
  font-weight: bold;
}

.departments-list__actions-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
}

.departments-list__content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 93%;
  top: 0;
  max-height: 100%;
}

.departments-list__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 5px;
  padding-bottom: 4rem;
  z-index: 10;
}

.avatar-department {
  border-left: 1px solid rgba(128, 128, 128, 0.316);
}

.th {
  backdrop-filter: blur(9px);
  font-weight: 100;
  border-top: 1px solid rgba(128, 128, 128, 0.316);
  font-size: 13px;
}

.actions-th {
  width: 10%;
}

.department-name {
  font-weight: 600;
}

.actions-td {
  display: flex;
  align-items: center;
}

.last-item {
  position: relative;
}

.trigger-pagination {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: rgba(0, 0, 0, 0);
}

.actions__btn.del {
  margin-left: 5px;
}
</style>