<template>
  <!-- Главный Блок для взаимодействия с ОТДЕЛЕНИЯМИ -->
  <div class="departments-main">
    <div class="departments-main__wrapper">
      <!-- Список Отделений -->
      <departmentsListComp :departments="departments" :is-load-departments="isLoadDepartments" :is-deleted="isDeleted"
        :is-active-hospital="isActiveHospital"
        @load-department="(department: DepartmentClient) => appendDepartment(department)"
        @update-department="(department: DepartmentClient) => updateDepartment(department)"
        @pagination-up="uploadNewPageItems" @department-filter="filteredDepartments" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
// COMPONENTS
import departmentsListComp from './departmentsListComp.vue';
// API
import { getAllDepartmentsDB, getDepartmentsByHospitalIdDB } from '../../api/superuser/departmentsApi';
import { getDepartmentsMyHospital__chief } from '../../api/hospitalChief/departmentsApi';
import { getHospitalByID } from '../../api/superuser/hospitalsApi';
import { getInfoMyHospital_chief } from '../../api/hospitalChief/hospitalsApi';
// TYPES
import { ArrayDepartmentClient, DepartmentClient } from '../../types/departmentType';
// STORE
import useMainStore from '../../store/mainStore';
import useDataStore from '../../store/dataStore';
import useAuthStore from '../../store/authStore';
import { useRoute } from 'vue-router';


const route = useRoute();
const mainStore = useMainStore();
const authStore = useAuthStore();
const dataStore = useDataStore();

// ========================================  DATA  ========================================

// Полученный с БД массив данных
const departments = ref<ArrayDepartmentClient>([]);
const isLoadDepartments = ref<boolean>(false);
const isSelectedHospital = ref<boolean>(false);
const selectHospitalId = ref<null | number>(null);
const isActiveHospital = ref<boolean>(true);
const isDeleted = ref<boolean>(false);
const page = ref<number>(1);


// ========================================  METHODS  ========================================
// Получение всех отделений больницы
async function getAllDepartmetns(isDeleted?: boolean | undefined, page?: number | undefined, hospitalId?: number | undefined) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getAllDepartmentsDB(isDeleted, page, hospitalId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getDepartmentsMyHospital__chief(isDeleted, page);
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsMainComp: getAllHospitalBeds  => ${err}`);
  }
}

// Получение больницы по ID
async function getHospitalByIDGeneral(hospitalId: number) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getHospitalByID(hospitalId);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getInfoMyHospital_chief();
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsMainComp: getAllHospitalBeds  => ${err}`);
  }
}

// Получение всех отделений выбранной больницы
async function getDepartmentsByHospitalId(hospitalId: number, isDeleted?: boolean | undefined, page?: number | undefined) {
  try {
    // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
    if (authStore.isSuperUser === true) {
      return await getDepartmentsByHospitalIdDB(hospitalId, isDeleted, page);
    }
    // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
    return await getDepartmentsMyHospital__chief(isDeleted, page);
  } catch (err) {
    throw new Error(`components/hospitalBeds/hospitalBedsMainComp: getDepartmentsByHospitalId  => ${err}`);
  }
}

// Отрабатывает при создании нового отделения
function appendDepartment(department: DepartmentClient) {
  try {
    dataStore.departments.push(department);
    departments.value = dataStore.departments;
  } catch (err) {
    throw new Error(`components/departments/departmentsMainComp: appendDepartment  => ${err}`);
  }
}

// Отрабатывает когда происходит обновление данных отделения
function updateDepartment(departmentUpdated: DepartmentClient) {
  try {
    // Если уже выбрана больница и query-параметр hospital существует
    if (isSelectedHospital.value && selectHospitalId.value !== null) {
      // Если выбрана больница через query-параметр, и если мы не изменяли принадлежность отделения к текущей выбранной больнице
      // то мы просто заменяем данные отделения не удаляя его из массива отделений этой больницы
      dataStore.departments = dataStore.departments.filter((department) => {
        if (department.id === departmentUpdated.id && departmentUpdated.hospitalId === selectHospitalId.value) {
          department.hospitalId = departmentUpdated.hospitalId;
          department.name = departmentUpdated.name;
        } else if (department.id === departmentUpdated.id && departmentUpdated.hospitalId !== selectHospitalId.value) {
          // Если мы изменили данные и также поменяли отделению принадлежность к выбранной больнице, то она удаляется 
          // с массива отделений этой больницы так как больше не принадлежит ей 
          return false;
        }
        // по умолчанию пропускаем все элементы массива
        return true;
      });
      // также локально изменяем массив для отображения изменений в режиме реального времени
      departments.value = dataStore.departments;
    }
    else {
      // Случай когда больница не выбрана в query-параметре а список отделений был открыт с панели навигации
      dataStore.departments.filter((department: DepartmentClient) => {
        if (department.id === departmentUpdated.id) {
          department.hospitalId = departmentUpdated.hospitalId;
          department.hospital = departmentUpdated.hospital;
          department.name = departmentUpdated.name;
        }
        return true;
      });
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsMainComp: updateDepartment  => ${err}`);
  }
}

// Подгрузка новых данных по пагинации
async function uploadNewPageItems() {
  try {
    // Проверка, если на сервере есть следующая страница с данными то выполнять подгрузку этой страницы
    if (mainStore.isDepartmentPaginationHasNext === true && departments.value.length >= 30) {
      page.value += 1;
      const employeesNextPage: any = await getAllDepartmentsDB(isDeleted.value, page.value, undefined);
      // Добавляем в уже существующий массив элементов новые элементы пришедшие с другой страницы пагинации
      dataStore.departments = dataStore.departments.concat(employeesNextPage);
      departments.value = dataStore.departments;
    }
  } catch (err) {
    throw new Error(`components/employees/employeesComp: uploadNewPageItems  => ${err}`);
  }
}

// Функция фильтрует массив Отделений относительно фильтр-данных
async function filteredDepartments(filterData: { hospitalName: string | null, isDeleted: boolean }) {
  try {
    isDeleted.value = false;
    page.value = 1;
    isLoadDepartments.value = true;

    // Если фильтр-данные пусты, то возвращаем список устройств в исходное состояние
    if (filterData.hospitalName === undefined && !filterData.isDeleted) {
      if (dataStore.departments.length) {
        departments.value = dataStore.departments;
      } else {
        // так как поле isDeleted в getAllHospitalDB и isDeleted не одно и то же, мы меняем isActive на противоположное значение
        dataStore.departments = await getAllDepartmetns(false, page.value);
        departments.value = dataStore.departments;
      }
      // Возвращаем переменные для пагинации в исходное состояние
      isDeleted.value = false;
      mainStore.isDepartmentPaginationHasNext = true;
      page.value = 1;
      return;
    }
    // Если данные для фильтрации были указаны то получаем весь список Отделений в системе и фильтруем его по фильтр данным
    else {
      let fetchAllDepartments = await getAllDepartmetns(filterData.isDeleted, undefined);
      let filteredDataList = fetchAllDepartments;
      // Фильтрация по hospitalName
      if (filterData.hospitalName) {
        filteredDataList = filteredDataList.filter((department: DepartmentClient) => {
          if (filterData.hospitalName) {
            return !!department.hospital.name?.toLocaleLowerCase()?.includes(filterData.hospitalName.toLocaleLowerCase());
          }
          return true;
        });
      }
      // Фильтрация по isActive
      if (filterData.isDeleted) {
        isDeleted.value = true;
        filteredDataList = await getAllDepartmetns(filterData.isDeleted, undefined);
        // Если введены другие данные в фильтр панель
        if (filterData.hospitalName) {
          filteredDataList = filteredDataList.filter((department: DepartmentClient) => {
            // Если в фильтр панеле указано название больницы, то проверяем сопоставляется ли оно
            if (department.hospital.name && filterData.hospitalName) {
              if (department.hospital.name.toLocaleLowerCase().includes(filterData.hospitalName?.toLocaleLowerCase())) {
                return true;
              }
              else return false;
            }
          });
        }
      }
      departments.value = filteredDataList;
    }
  } catch (err) {
    throw new Error(`components/departments/departmentsMainComp: filteredDepartments  => ${err}`);
  } finally {
    isLoadDepartments.value = false;
  }
}

// ================================================================================  WATCH  ================================================================================
// Отслеживает удаление query-параметра hospital в случае если мы переходим со страницы выбранных отделений конкретной больницы 
// на список всех отделений
watch(() => route.query['hospital'], async (newValue) => {
  if (newValue === undefined) {
    try {
      isLoadDepartments.value = true;
      dataStore.departments.length = 0;
      departments.value.length = 0;
      // Получаем массив отделений задавая при этом каждому отделению название его больницы
      dataStore.departments = await getAllDepartmetns(false, page.value);
      departments.value = dataStore.departments;
      isSelectedHospital.value = false;
      selectHospitalId.value = null;
    } catch (err) {
      throw new Error(`components/departments/departmentsMainComp: watch[fetch departments]  => ${err}`);
    } finally {
      isLoadDepartments.value = false;
    }
  }
});

// Получение отделений для управляющего больницей
watch(() => authStore.isChief, async (isChief) => {
  if (isChief) {
    try {
      isLoadDepartments.value = true;
      dataStore.departments = await getDepartmentsMyHospital__chief(isDeleted.value, page.value);
      departments.value = dataStore.departments;
    } catch (err) {
      throw new Error(`components/departments/departmentsMainComp: watch[fetch departments__chief]  => ${err}`);
    } finally {
      isLoadDepartments.value = false;
    }
  }
});

// Получение отделений для суперпользователя
watch(() => authStore.isSuperUser, async (isSuperuser) => {
  // Получение списка отделений если есть query-параметр открытой больницы
  if (isSuperuser) {
    try {
      isLoadDepartments.value = true;
      const hospitalId = route.query['hospital'];
      if (hospitalId && +hospitalId > 0) {
        selectHospitalId.value = +hospitalId;
        isSelectedHospital.value = true;
        dataStore.departments = await getDepartmentsByHospitalId(+hospitalId, false);
        departments.value = dataStore.departments;
        return;
      } else {
        dataStore.departments = await getAllDepartmetns(false);
        departments.value = dataStore.departments;
      }
    } catch (err) {
      throw new Error(`components/departments/departmentsMainComp: watch[fetch departments]  => ${err}`);
    } finally {
      isLoadDepartments.value = false;
    }
  }
});

// ========================================  LIFECYCLE HOOKS  ========================================
// Получение массива ОТДЕЛЕНИЙ с БД
onMounted(async () => {
  // Получение отделений для управляющего больницей
  if (authStore.isChief === true) {
    try {
      isLoadDepartments.value = true;
      dataStore.departments = await getAllDepartmetns(isDeleted.value, page.value);
      departments.value = dataStore.departments;
    } catch (err) {
      throw new Error(`components/departments/departmentsMainComp: watch[fetch departments__chief]  => ${err}`);
    } finally {
      isLoadDepartments.value = false;
    }
  }
  // Получение отделений для суперпользователя
  if (authStore.isSuperUser === true) {
    try {
      isLoadDepartments.value = true;
      // Получение списка отделений если есть query-параметр открытой больницы
      const hospitalId = route.query['hospital'];
      if (hospitalId && +hospitalId > 0) {
        selectHospitalId.value = +hospitalId;
        isSelectedHospital.value = true;
        dataStore.departments = await getAllDepartmetns(isDeleted.value, page.value, +hospitalId);
        departments.value = dataStore.departments;
        // Получаем выбранную в query парметр больницу для того чтобы получить неактивные отделения, если эта больница не активна
        const currentHospital = await getHospitalByIDGeneral(+hospitalId);
        if (currentHospital.deleted) {
          isActiveHospital.value = false;
        }
        return;
      } else {
        dataStore.departments = await getAllDepartmetns(isDeleted.value, page.value);
        departments.value = dataStore.departments;
      }
    } catch (err) {
      throw new Error(`components/departments/departmentsMainComp: watch[fetch departments__superuser]  => ${err}`);
    } finally {
      isLoadDepartments.value = false;
    }
  }
});

// При размонтировании компонента происходит очистка массива dataStore если при монтировании этого компонента была выбрана больница и был query-параметр hospital
// Нужно для того чтобы по новой подгрузить актуальные данные с сервера 
onBeforeUnmount(() => {
  if (isSelectedHospital.value === true) {
    dataStore.departments.length = 0;
    departments.value.length = 0;
  }
})

</script>

<style scoped>
.departments-main {
  width: 100%;
  height: 100%;
  padding: 0 1rem 0 4rem;
}

.departments-main__wrapper {
  width: 100%;
  height: 107%;
  margin-top: 1rem;
  background-color: var(--bg-color-white);
}
</style>