import { ArrayDepartmentClient, DepartmentClient } from '../types/departmentType';
import { getAllDepartmentsDB } from '../api/superuser/departmentsApi';
import { ref } from 'vue';


// Компонуемый файл для получения списка отделений и отображения их в выпадающем меню
function useLoadingDepartments() {

    // =================  DATA  ===================

    interface SelectedDeaprtment {
        name: string | null;
        id: number;
        deleted: number | null;
        hospital: {
            name: string | null;
            address: string | null;
            id: number;
            deleted: number | null;
        }
    }
    // Списки доступных элементов для селект-меню
    const departmentList = ref<ArrayDepartmentClient>([]);
    const selectedDepartment = ref<DepartmentClient | SelectedDeaprtment | null>(null);
    const selectedDepartmentName = ref<string | null>(null);
    const isLoadingDepartmentsFetch = ref<boolean>(true);


    // =================  METHODS  ===================

    // Функция получает массив отделений с БД для отображения в селект-меню
    async function loadDepartments() {
        try {
            departmentList.value = await getAllDepartmentsDB(false);
        } catch (err) {
            throw new Error(`compsables/loadDepartmentsList.ts: loadDepartments => ${err}`);
        }
    }

    // Функция выполняется когда в селект-меню убираем отделение (оно очищается из выбранных)
    function clearDepartment() {
        try {
            selectedDepartment.value = null;
            selectedDepartmentName.value = null;
            console.log('clear')
        } catch (err) {
            throw new Error(`compsables/loadDepartmentsList.ts: clearDepartment  => ${err}`)
        }
    }

    // Выбор отделения в селект-меню
    function selectDepartment(department: DepartmentClient | null) {
        try {
            if(department === null) {
                selectedDepartment.value = null;
                selectedDepartmentName.value = null;
                return;
            }
            console.log('pre set')
            console.log(department)
            selectedDepartment.value = department;

            if (department?.name) {
                selectedDepartmentName.value = department?.name;
                console.log(department)
                console.log('set')
            }
        } catch (err) {
            throw new Error(`compsables/loadDepartmentsList.ts: selectDepartment => ${err}`);

        }
    }

    return {
        departmentList,
        selectedDepartment,
        selectedDepartmentName,
        isLoadingDepartmentsFetch,

        loadDepartments,
        clearDepartment,
        selectDepartment,
    }
}

export default useLoadingDepartments;