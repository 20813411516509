<template>
  <v-dialog v-model="modelValue" width="auto">
    <v-card class="dialog__wrapper">

      <!-- Крутилка загрузки -->
      <span class="progress-circular" v-if="isShowLoadingData">
        <v-progress-circular indeterminate bg-color="var(--bg-color-white)" color="var(--bg-color-default)" :size="60"
          :width="6"></v-progress-circular>
      </span>

      <form class="dialog__form">
        <!-- Превью -->
        <div class="dialog__form--preview-block">
          <input id="preview-image" v-show="false" />
          <label class="preview-image" for="preview-image">
            <v-icon icon="mdi-hospital-building" color="var(--color-default)" size="80px"></v-icon>
          </label>
          <h1 class="preview-name">{{ hospitalFormCopy.name }}</h1>
        </div>

        <!-- Общие данные -->
        <div class="dialog__form--general-data">
          <div class="data-body">
            <!-- Имя -->
            <div class="data-body__item">
              <v-card-subtitle class="mb-2">Название</v-card-subtitle>
              <v-text-field class="general-data__input" bg-color="var(--bg-color-white)" v-model="name"
                color="var(--color-default)" rounded="xs" :density="'compact'" variant="outlined"
                hide-details></v-text-field>
            </div>

            <!-- Адрес -->
            <div class="data-body__item">
              <v-card-subtitle class="mb-2">Адрес</v-card-subtitle>
              <v-text-field class="general-data__input" bg-color="var(--bg-color-white)" v-model="address"
                color="var(--color-default)" rounded="xs" :density="'compact'" variant="outlined"
                hide-details></v-text-field>
            </div>

          </div>
          <!-- Управляющий -->
          <chiefListComp style="width: 100% !important; margin-top: 1.5rem;" :label-compbox="'Управляющий'"
            :item-list="chiefList" @load-items="loadEmployees" @close-menu="chiefList.length = 0"
            @clear-chief="clearChief" @select-chief="(e) => selectChief(e)" :selected-chief-name="chiefName"
            :is-required="false" />

        </div>
      </form>
      <div class="dialog__actions">
        <!-- Сохранить -->
        <v-btn class="mr-6" :loading="isLoadingUpdate" @click="confirmToUpdateHospital" variant="flat"
          style="color: white; font-weight: normal; font-size: 12px;" color="var(--bg-color-default)">
          Сохранить
        </v-btn>
        <!-- Сбросить изменения -->
        <v-btn variant="tonal" style="margin-right: auto; font-size: 12px;" color="var(--bg-color-default)"
          @click="resetChanges">
          Сбросить изменения
        </v-btn>

        <v-btn variant="outlined" color="red" @click="handlerClose" style="font-size: 12px;">
          Закрыть
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
// COMPONENTS
import chiefListComp from './chiefListComp.vue';
// TYPES
import { HospitalClient, HospitalForCreate } from '../../types/hospitalType';
import { UserClient } from '../../types/userType';
// API
import { getHospitalByID, putHospitalById } from '../../api/superuser/hospitalsApi';
import { getEmployeesDB } from '../../api/superuser/employeesApi';
// STORE
import useMainStore from '../../store/mainStore';
import useDataStore from '../../store/dataStore';
// VUE
import { ref, defineModel, defineEmits, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';

const dataStore = useDataStore();
const store = useMainStore();
const route = useRoute();
const modelValue = defineModel<boolean>();
// ======================================   EMITS   ======================================
const emit = defineEmits<{
  close: [],
  success: [],
  updateHospital: [hospital: HospitalClient],
}>();

// ======================================   DATA   ======================================
const page = ref<number>(1)
const isShowLoadingData = ref<boolean>(false);
const id = ref<number>(0);
const name = ref<string | null>('');
const address = ref<string | null>('');
const chiefName = ref<string | null>('');
const chiefList = ref<Array<any>>([]);
const selectedChief = ref<UserClient | null>(null);
const isLoadingUpdate = ref<boolean>(false);
const hospitalFormCopy = ref({
  name: null as null | string,
  address: null as null | string,
  chiefName: null as null | string,
});

// ======================================   METHODS   ======================================
// Получениe списка сотрудников системы
async function loadEmployees() {
  try {
    page.value += 1
    const newChiefs: UserClient[] = await getEmployeesDB(page.value, true, false);
    dataStore.employees.push(...newChiefs)
    chiefList.value = [...dataStore.employees];
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsDialogComp.vue: loadEmployees  => ${err}`);
  }
}

// Выбор управляющего больницей в селект-меню
function selectChief(user: UserClient | null) {
  selectedChief.value = user;
  if (user?.fullName) {
    chiefName.value = user?.fullName;
  }
}

// Функция выполняется когда в селект-меню убираем управляющего (он очищается из выбранных)
function clearChief() {
  try {
    selectedChief.value = null;
    chiefName.value = '';
  } catch (err) {
    throw new Error(`components/hospitals/hospitalDialogComp.vue: clearChief  => ${err}`)
  }
}

function bundleForUpdate(entries: Array<Array<string>>) {
  try {
    const readyObject: HospitalForCreate = {};
    entries.forEach((entry) => {
      if (entry[0] === 'chiefName') {
        readyObject.chief_id = selectedChief.value?.id;
      } else {
        readyObject[entry[0]] = entry[1];
      }
    });
    return readyObject;
  } catch (err) {
    throw new Error(`components/hospitals/hospitalDialogComp.vue: bundleForUpdate  => ${err}`)
  }
}

// Подтверждение сохранения выбранной больницы
async function confirmToUpdateHospital() {
  try {
    // сравниваем два объекта на наличие изменения данных в полях ввода (Для исключения пустого запроса)
    const { entries, isCompare } = store.compareObjectsFetchFields(hospitalFormCopy.value, {
      name: name.value,
      address: address.value,
      chiefName: chiefName.value,
    });
    // Если два объекта отличаются то обрабатываем запрос к серверу на изменение данных
    if (isCompare === false) {
      if (address.value && name.value && id.value > 0) {
        try {
          isLoadingUpdate.value = true;
          const changedFields = bundleForUpdate(entries);
          const updatedHospital = await putHospitalById(id.value, changedFields);
          emit('updateHospital', updatedHospital);
          emit('success');
        } catch (err) {
          throw new Error(`components/hospitals/hospitalDialogComp.vue: запрос на обновление организации  => ${err}`)
        } finally {
          isLoadingUpdate.value = false;
        }
      }
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalDialogComp.vue: confirmToUpdateHospital  => ${err}`)
  }
}

// Функция разбивает ключи получаемого объекта по реактивным переменным для отображаения их в полях ввода
function parsingObject(hospital: HospitalClient) {
  try {
    if (hospital.name) {
      name.value = hospital.name;
    }
    address.value = hospital.address;
    if (hospital.chief?.fullName && hospital.chief?.fullName.length > 0) {
      chiefName.value = hospital.chief?.fullName;
    }
    selectedChief.value = hospital.chief;
  } catch (err) {
    throw new Error(`components/hospitals/hospitalDialogComp.vue: parsingObject  => ${err}`)
  }
}

// Сбросить изменения в форме 
function resetChanges() {
  try {
    const isCompare = store.compareObjects(hospitalFormCopy.value, {
      name: name.value,
      address: address.value,
      chiefName: chiefName.value,
    });
    if (isCompare === false) {
      name.value = hospitalFormCopy.value.name;
      address.value = hospitalFormCopy.value.address;
      chiefName.value = hospitalFormCopy.value.chiefName;
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalDialogComp.vue: resetChanges  => ${err}`)

  }
}

// Обработчик закрытия формы
function handlerClose() {
  id.value = 0;
  chiefName.value = '';
  name.value = '';
  address.value = '';
  selectedChief.value = null;
  hospitalFormCopy.value = {
    address: null,
    chiefName: null,
    name: null,
  }
  emit('close');
}

// ======================================   WATCH   ======================================
// Получение данных выбранного объекта в списке
watch(() => route.query['select-hospital'], async (newValue) => {
  try {
    isShowLoadingData.value = true;
    if (typeof newValue === 'string' && +newValue > 0) {
      const hospital = await getHospitalByID(+newValue);
      id.value = +newValue;
      parsingObject(hospital);
      // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
      hospitalFormCopy.value = {
        name: name.value,
        address: address.value,
        chiefName: chiefName.value,
      }
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalDialogComp.vue: watch  => ${err}`)
  } finally {
    isShowLoadingData.value = false;
  }

});

// ======================================   LIFECYCLE HOOKS   ======================================
onMounted(async () => {
  // Получение query параметра select-hospital
  dataStore.employees = await getEmployeesDB(1, true);

  try {
    if (route.query['select-hospital']) {
      const hospitalIdQuery = route.query['select-hospital']
      if (typeof hospitalIdQuery === 'string' && +hospitalIdQuery > 0) {
        // Загружаем данные выбранной больницы если query параметр select-hospital существует
        try {
          isShowLoadingData.value = true;
          id.value = +hospitalIdQuery;
          const hospital = await getHospitalByID(id.value);
          parsingObject(hospital);
        } catch (err) {
          throw new Error(`components/hospitals/hospitalDialogComp.vue: onMounted[fetch hospital-data]  => ${err}`)
        } finally {
          isShowLoadingData.value = false;
        }
      }
    } else {
      emit('close');
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalDialogComp.vue: onMounted[fetch query-param - 'select-hospital']  => ${err}`)
  }

  // Создаем копию объекта полей формы для дальнейшего их сравнения при отправке запроса
  try {
    hospitalFormCopy.value = {
      name: name.value,
      address: address.value,
      chiefName: chiefName.value,
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalDialogComp.vue: watch  => ${err}`)
  }
});

</script>

<style scoped>
.progress-circular {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.45);
  z-index: 999;
}

.dialog__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 900px;
  height: max-content;
  background-color: var(--bg-color-block);
}

.dialog__form {
  width: 100%;
  height: max-content;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-block);
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem;
  background-color: var(--bg-color-white);
  margin-bottom: 3rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dialog__form--preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: max-content;
  padding: 2rem 0;
  background-color: rgba(239, 246, 248, 1);
}

.preview-image {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.image {
  width: 100%;
  height: 100%;
}

.preview-name {
  font-size: 26px;
  font-weight: 500;
  margin-top: 1rem;
}

.data-body {
  display: flex;
  gap: 1rem;
  padding-top: 32px;
}

.data-body__item {
  flex: 1;
}

.dialog__form--general-data {
  width: 80%;
  padding: 0 2rem;
  background-color: var(--bg-color-white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.general-data__input {
  margin-bottom: 1rem;
}

.general-data__departments-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1.5rem;
  border: 1px solid black;
}

.general-data__departments-amount p {
  color: var(--color-default);
}
</style>