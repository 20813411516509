<template>
  <!-- Главный Блок для взаимодействия с Больницами -->
  <div class="hospitals-main">
    <div class="hospitals-main__wrapper">
      <hospitalsListComp :hospitals="hospitals"
        @append-hospital="(hospital: HospitalClient) => appendHospital(hospital)"
        @update-hospital="(hospital: HospitalClient) => updateHospitals(hospital)"
        @disable-hospital="(hospitalId: number) => disableHospital(hospitalId)"
        @hospital-filter="(filterData) => filteredHospitals(filterData)" :isActive="isActive"
        :is-loading-data="isLoadingData" :is-not-hospitals="isNotHospitals" :is-not-hospitals-message="causeEmptyMsg" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
// COMPONENTS
import hospitalsListComp from './hospitalsListComp.vue';
// API
import { getAllHospitalDB } from '../../api/superuser/hospitalsApi';
// TYPES
import { HospitalClient } from '../../types/hospitalType';
// STORE
import useDataStore from '../../store/dataStore';

const dataStore = useDataStore();

// =======================   DATA   ================================

// Полученный с БД массив данных
const hospitals = ref<Array<HospitalClient> | []>([]);
const page = ref<number>(1);
const isActive = ref<boolean>(true);
const isLoadingData = ref<boolean>(false);
const isNotHospitals = ref<boolean>(false);
const causeEmptyMsg = ref<string>('Организации не найдены');
const causeList = ref<Array<string>>([
  "Организации не найдены",
  "Организации не найдены",
]);

// =======================   METHODS   ================================

// Функция выполняется когда происходит успешное создание новой больницы
async function appendHospital(newHospital: HospitalClient) {
  try {
    // Получаем массив dataStore.hospitals с сервера если его нет
    if (!dataStore.hospitals.length) {
      dataStore.hospitals = await getAllHospitalDB(false);
      hospitals.value = dataStore.hospitals;
    }
    // Добавляем новую больницу в массив
    dataStore.hospitals.push(newHospital);
    hospitals.value = dataStore.hospitals;
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsMainComp: appendHospital  => ${err}`);
  }
}

// Функция выполняется когда происходит успешное обновление данных больницы
async function updateHospitals(hospital: HospitalClient) {
  try {
    dataStore.hospitals = hospitals.value.map((item) => {
      if (item.id === hospital.id) {
        return hospital;
      } else {
        return item;
      }
    });
    hospitals.value = dataStore.hospitals;
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsMainComp: updateHospitals  => ${err}`);
  }
}

// Функция выполняется когда деактивируем больницу в списке
// Нужна для того чтобы в реал-тайме убрать деактивируемую больницу со списка
async function disableHospital(hospitalId: number) {
  try {
    // Получаем массив dataStore.hospitals с сервера если его нет
    if (!dataStore.hospitals.length) {
      dataStore.hospitals = await getAllHospitalDB(false);
      hospitals.value = dataStore.hospitals;
    }
    // Фильтруем массив убирая с него деактивируемую больницу
    dataStore.hospitals = dataStore.hospitals.filter((hospital) => {
      return hospital.id !== hospitalId;
    });
    hospitals.value = dataStore.hospitals;
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsMainComp: disableHospital  => ${err}`);
  }
}

// Функция фильтрует массив больниц относительно фильтр-данных
async function filteredHospitals(filterData: { name: string | undefined, isActive: boolean }) {
  try {
    isLoadingData.value = true
    // Если фильтр-данные пусты, то возвращаем список больниц в исходное состояние
    if (filterData.name === undefined && filterData.isActive) {
      isNotHospitals.value = false;
      causeEmptyMsg.value = causeList.value[0];
      if (dataStore.hospitals.length) {
        hospitals.value = dataStore.hospitals;
      } else {
        // так как поле isDeleted в getAllHospitalDB и isActive не одно и то же, мы меняем isActive на противоположное значение
        hospitals.value = await getAllHospitalDB(filterData.isActive, page.value);
      }
      return;
    }
    // Если данные для фильтрации были указаны то получаем весь список больниц в системе и фильтруем его по фильтр данным
    else {
      let filteredDataList = await getAllHospitalDB(!filterData.isActive, undefined);
      // Фильтрация по Названию
      if (filterData.name) {
        filteredDataList = filteredDataList.filter((hospital: HospitalClient) => {
          // фильтрация по имени
          if (filterData.name) {
            return !!hospital.name?.toLocaleLowerCase()?.includes(filterData.name.toLocaleLowerCase());
          }
          return true;
        });
      }

      // Фильтрация по isActive
      if (filterData.isActive === false) {
        hospitals.value = await getAllHospitalDB(!filterData.isActive, page.value);
      }
      if (filteredDataList.length === 0) {
        isNotHospitals.value = true;
        causeEmptyMsg.value = causeList.value[1];
      } else {
        isNotHospitals.value = false;
        causeEmptyMsg.value = causeList.value[0];
      }
      hospitals.value = filteredDataList;
    }

  } catch (err) {
    throw new Error(`components/employees/employeesComp: filteredUsers  => ${err}`);
  } finally {
    isLoadingData.value = false
  }
}

// =======================   LIFECYCLE HOOKS   ================================

// Получение массива БОЛЬНИЦ с БД
onMounted(async () => {
  try {
    isLoadingData.value = true;
    // Если ранее не был получен список больниц, то получаем его с сервера
    if (!dataStore.hospitals.length) {
      dataStore.hospitals = await getAllHospitalDB(!isActive.value, page.value);
      hospitals.value = dataStore.hospitals;
    } else {
      // Если в dataStore есть массив больниц, то данные не запрашиваются у сервера
      hospitals.value = dataStore.hospitals;
    }
  } catch (err) {
    throw new Error(`components/hospitals/hospitalsMainComp: onMounted  => ${err}`);
  } finally {
    isLoadingData.value = false;
  }
});

</script>

<style scoped>
.hospitals-main {
  width: 100%;
  height: 100%;
  padding: 0 1rem 0 4rem;
}

.hospitals-main__wrapper {
  width: 100%;
  height: 107%;
  margin-top: 1rem;
  background-color: var(--bg-color-white);
}
</style>