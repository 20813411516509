<template>
    <div class="group-hospital__wrapper">
        <v-card-subtitle class="mb-3">
            {{ (props.headerTitle) ? props.headerTitle : 'Отделение, к которому принадлежит палата' }}
            <span style="font-weight: 600; color: red; margin-left: 0.5rem;">
                {{ (isNotNamesList === false) ? '' : 'У выбранной организации нет отделений, создайте его' }}
            </span>
        </v-card-subtitle>
        <v-combobox class="group-items" :clearable="true" :placeholder="props.labelCompbox" :items="filteredListNames"
            :loading="props.isLoading" @update:model-value="(name: string) => selectDepartments(name)"
            @click:clear="handlerClear" rounded="lg" :rules="nameRules" :error="props.required?.isTriggerErr"
            :error-messages="(props.required?.isTriggerErr === true) ? props.required?.errorMessage : ''"
            :disabled="(isNotNamesList === true) ? isNotNamesList : props.isDisabled" v-model="departmentName"
            variant="outlined" bg-color="var(--bg-color-white)" color="var(--color-default)"
            base-color="var(--color-default)" :density="'compact'">
            <!-- Элемент группы -->
            <template v-slot:item="{ item, props }">
                <div v-bind="props" class="group-items__item">
                    <p class="item__value">{{ item.value }}</p>
                </div>
            </template>

        </v-combobox>
    </div>
</template>

<script setup lang="ts">
// API
import { getAllDepartmentsDB } from '../../api/superuser/departmentsApi';
import { getAllDepartmentsDB__chief } from '../../api/hospitalChief/departmentsApi';
// TYPES
import { DepartmentClient } from '../../types/departmentType';
// STORE
import useDataStore from '../../store/dataStore';
import useAuthStore from '../../store/authStore';
// VUE
import { useRoute } from 'vue-router';
import { ref, watch, computed, defineProps, defineEmits, defineModel, onMounted } from 'vue';

const authStore = useAuthStore();
const dataStore = useDataStore();
const route = useRoute();

// =================================  V-MODEL  =================================
defineModel<string>('checked');

// =================================  PROPS  =================================
const props = defineProps<{
    labelCompbox: string,
    itemList: Array<any>,
    selectedDepartmentName: string | null,
    isLoading?: boolean,
    headerTitle?: string,
    filterByHospitalId?: number | null;
    required?: {
        isRequired: boolean;
        errorMessage: string;
        isTriggerErr: boolean;
    };
    isDisabled?: boolean;
}>();

// =================================  EMITS  =================================
const emit = defineEmits<{
    loadItems: [],
    closeMenu: [],
    selectDepartment: [DepartmentClient | null],
    clearDepartment: [],
}>()

// =================================  DATA  =================================
const isLoading = ref<boolean>(false);
const departmentName = ref<string | null>(null);
const listNames = ref<Array<string | any>>([]);
const listDepartments = ref<any>([]);
const isNotNamesList = ref<boolean>(false);

// Правила валидации Названия
const nameRules = ref([
    (value: string) => {
        if (props.required?.isRequired === true) {
            if (value && value.length > 0) {
                return true;
            } else {
                return props.required?.errorMessage;
            }
        } else {
            return true;
        }
    },
]);

// =================================  METHODS  =================================

function handlerClear() {
    emit('clearDepartment');
}

// Функция для отправки выбранного отделения в диалоговое окно редактирования палаты 
async function selectDepartments(departmentName: string) {
    if (props.filterByHospitalId) {
        listDepartments.value.forEach((department: DepartmentClient) => {
            if (departmentName === department.name) {
                emit('selectDepartment', department);
            }
        });
    }
}

async function getAllDepartments(isDeleted?: boolean | undefined, page?: number | undefined, hospitalId?: number | undefined) {
    try {
        // Если пользователь авторизован как СУПЕРПОЛЬЗОВАТЕЛЬ
        if (authStore.isSuperUser === true) {
            return await getAllDepartmentsDB(isDeleted, page, hospitalId);
        }
        // Если пользователь авторизован как УПРАВЛЯЮЩИЙ больницы
        return await getAllDepartmentsDB__chief(isDeleted, page);
    } catch (err) {
        throw new Error(`components/hospitalRooms/departmentListComp.vue: getAllDepartments  => ${err}`);

    }
}

// Функция получает список отделений относительно того какая больница выбрана в другом селект меню (если это требуется)
async function fetchedDepartmentsByHospitalId(hospitalId: number | null | undefined) {
    try {
        // Если пришедший айдишник больницы равен null или undefined то полностью обнуляем все Данные
        if (hospitalId === null) {
            emit('selectDepartment', null);
            departmentName.value = null;
            listNames.value = [];
            isNotNamesList.value = false;
            return;
        }
        // emit('selectDepartment', null);
        // departmentName.value = null;
        // listNames.value = [];

        const fetchedDepartments = await getAllDepartments(false, undefined, hospitalId);

        fetchedDepartments.forEach((department: DepartmentClient) => {
            if (department.deleted === null) {
                listDepartments.value.push(department);
                listNames.value.push(department.name)
            }
        })

        if (listNames.value.length === 0) {
            isNotNamesList.value = true;
        } else {
            isNotNamesList.value = false;
        }
    } catch (err) {
        throw new Error(`components/hospitalRooms/departmentListComp.vue: fetchedDepartmentsByHospitalId  => ${err}`);
    }
}

const filteredListNames = computed(() => [...new Set(listNames.value)])

// =================================  WATCH  ===================================

// Отслеживает изменение приходящего ID больницы в пропсы
watch(() => props.filterByHospitalId, async (newValue) => {
    listNames.value.length = 0;
    departmentName.value = null;
    await fetchedDepartmentsByHospitalId(newValue);
});

// Отслеживает изменение массива приходящих элементов и отключает progress-circle
watch(() => props.itemList.length, (newLength: number, oldLength: number) => {
    if (oldLength === 0 && newLength > 0) {
        isLoading.value = false;
    }
});

// Отслеживание название отделения если он существует
watch(() => props.selectedDepartmentName, (newValue) => {
    departmentName.value = newValue;
})

// =================================  LIFECYCLE HOOKS  =================================
// onMounted( async() => {
//     try {
//         departmentName.value = props.selectedDepartmentName;
//         if(dataStore.departments.length) {
//             for (const department of dataStore.departments) {
//                 if(department.deleted === null) {
//                     listNames.value.push(department.name);
//                 }
//             }
//         } else {
//             // Получаем query-параметр hospital для получения отделений если выбрана больницы
//             let hospitalId: any = route.query['hospital'] ?? undefined;
//             if(typeof hospitalId === 'string') hospitalId = +hospitalId;
//             dataStore.departments = await getAllDepartments(false, undefined, hospitalId);
//             for (const department of dataStore.departments) {
//                 if(department.deleted === null) {
//                     listNames.value.push(department.name);
//                 }
//             }
//         }
//     } catch (err) {
//         throw new Error(`components/hospitalRooms/departmentListComp.vue: onMounted  => ${err}`);
//     }
// });

</script>

<style scoped>
.group-hospital__wrapper {
    width: 15% !important;
}

.group-items {
    width: 100% !important;
}

.group-items__selection-value {
    font-size: 0.9rem;
    margin: 0.5rem 0;
}

.group-items__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: .5rem .5rem .5rem 1rem;
    cursor: pointer;
}

.group-items__item:hover {
    background-color: var(--bg-color-gray);
}

.group-items__item+.group-items__item {
    border-top: var(--border-thin-light);
}

.item__value {
    width: 65% !important;
    padding: 0.1rem 0.5rem;
    font-size: 0.8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>