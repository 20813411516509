<template>
  <div class="auth-view">
    <!-- Уведомление об успешном выполнении -->
    <success-note-comp @complete="isCompleteAuth"></success-note-comp>
    <error-note-comp>{{ errMsg }}</error-note-comp>
    <v-card class="auth-form__container">
      <v-window class="selection-window" v-model="window">
        <!-- ФОРМА АВТОРИЗАЦИИ -->
        <v-window-item :value="windowItems[0]">
          <v-form class="auth-form" @submit.prevent>
            <!-- Заголовок "Авторизация" -->
            <v-card-title class="auth-form__title">Авторизация</v-card-title>

            <!-- Блок E-mail -->
            <div class="auth-form__input-block">
              <v-card-subtitle class="input-block__subtitle">E-mail</v-card-subtitle>
              <v-text-field class="input-block__input" bg-color="var(--bg-color-white)" v-model.trim="email"
                variant="outlined" name="email" :rules="emailRules" rounded="lg"
                placeholder="Адрес электронной почты"></v-text-field>
            </div>

            <!-- Блок Пароль -->
            <div class="auth-form__input-block">
              <v-card-subtitle class="input-block__subtitle">Пароль</v-card-subtitle>
              <v-text-field class="input-block__input" v-model.trim="password" variant="outlined" :rules="passwordRules"
                rounded="lg" :type="showPassword ? 'text' : 'password'"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="showPassword = !showPassword" bg-color="var(--bg-color-white)"
                placeholder="Введите пароль"></v-text-field>

            </div>
            <v-btn class="auth-form__confirm-btn" density="default" @click="login" :loading="isLoading">Подтвердить
            </v-btn>

            <!-- Блок Подсказка "Забыли пароль?" -->
            <div class="auth-form__hint">
              <p class="hint-question">Забыли пароль?</p>
              <a class="hint-href" @click.prevent="forwardWindow">
                Нажмите здесь
              </a>
              <v-spacer></v-spacer>
            </div>
          </v-form>
        </v-window-item>

        <!-- ФОРМА ВОССТАНОВЛЕНИЯ ПАРОЛЯ -->
        <v-window-item :value="windowItems[1]">
          <v-form class="auth-form" @submit.prevent>
            <!-- Заголовок "Авторизация" -->
            <v-card-title class="auth-form__title">Восстановление пароля</v-card-title>

            <!-- Блок E-mail -->
            <div class="auth-form__input-block">
              <v-card-subtitle class="input-block__subtitle">Укажите E-mail</v-card-subtitle>
              <v-text-field class="input-block__input" bg-color="var(--bg-color-white)" v-model.trim="email"
                @update:model-value="clearAllErrorInput" variant="outlined" name="email" :rules="emailRules"
                rounded="lg" placeholder="Адрес электронной почты" :error="isError"
                :error-messages="errorMsgInput"></v-text-field>
            </div>

            <v-card-subtitle class="auth-form__descr-block">
              Укажите вашу электронную почту, по которой вы входили в систему ранее,
              и на нее придёт новый сгенерированный пароль
            </v-card-subtitle>

            <div class="auth-form__actions">
              <v-btn class="reset-form__confirm-btn" density="default" @click="backWindow">Назад
              </v-btn>

              <v-btn class="reset-form__confirm-btn" density="default" @click="sendCodeEmail"
                :loading="isLoading">Подтвердить
              </v-btn>
            </div>

          </v-form>
        </v-window-item>

      </v-window>

    </v-card>
  </div>
</template>

<script setup lang="ts">
import { loginEmailPassword, forgotPassword, checkRank } from '../api/authApi';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useMainStore from '../store/mainStore';

const router = useRouter();
const store = useMainStore();

// =======================   DATA   ================================
const isLoading = ref<boolean>(false);
const showPassword = ref<boolean>(false);
const isValidEmail = ref<boolean>(false);
const errMsg = ref<string>('');
const email = ref<string>('');
const password = ref<string>('');
const window = ref<string>('auth');
const windowItems = ref<Array<string>>(['auth', 'reset']);
const isError = ref<boolean>(false);
const errorMsgInput = ref<string | null>(null);

// =======================   METHODS   ================================
// Возвращает на окно авторизации
function backWindow() {
  window.value = windowItems.value[0];
  email.value = '';
  clearAllErrorInput();
}

// Переход на окно восстановления пароля
function forwardWindow() {
  window.value = windowItems.value[1];
  email.value = '';
  clearAllErrorInput();
}

async function login() {
  try {
    if (
      email.value.length > 0 &&
      password.value.length > 0 &&
      isValidEmail.value === true
    ) {
      isLoading.value = true;
      const resp = await checkRank(email.value);

      if (resp.data && (resp.data.isSuperuser || resp.data.isChief)) {
        try {
          await loginEmailPassword(email.value, password.value);

        } catch (e) {
          errMsg.value = 'Не удалось авторизоваться';
          throw new Error(`views/AuthView: login  => ${e}`);
        }
      } else {
        store.activeErrorOperation(1200);
        errMsg.value = "Отсутствует доступ"
      }
    } else {
      errMsg.value = 'Заполните все поля';
      store.activeErrorOperation(1200);
    }
  } catch (err) {
    store.activeErrorOperation(1200);
    errMsg.value = 'Не удалось авторизоваться';
    throw new Error(`views/AuthView: login  => ${err}`);
  } finally {
    isLoading.value = false;
  }
}

// После успешной авторизации и скрытия уведомления, переходим на маршруты системы
function isCompleteAuth() {
  try {
    // Если успешное выполнение запроса происходит при нахожденеии на окне авторизации, то выполняется маршрутизация в систему
    if (window.value === 'auth') {
      router.push({ name: 'main' });
    }
    // Если успешное выполнение запроса происходит при нахожденеии на окне восстановления пароля, то переходим на окно авторизации
    else if (window.value === 'reset') {
      backWindow();
    }
  } catch (err) {
    throw new Error(`views/AuthView: isCompleteAuth  => ${err}`);
  }
}

// Сброс любых сообщений об ошибках, если начинаем вводить Поле ввода
function clearAllErrorInput() {
  try {
    isError.value = false;
    errorMsgInput.value = null;
  } catch (err) {
    throw new Error(`views/AuthView: clearErrorInput  => ${err}`);
  }
}

// Отправить код подтверждения на E-mail
async function sendCodeEmail() {
  try {
    isLoading.value = true;
    if (email.value.length > 0) {
      // Выполняется запрос если email есть и он валидный
      if (isValidEmail.value === true) {
        await forgotPassword(email.value);
        store.activeSuccessOperation(1200);
      }
      // Если введен некорректный E-mail
      else {
        errMsg.value = 'Введите корректный E-mail';
        store.activeErrorOperation(1500);
        isError.value = true;
        errorMsgInput.value = 'Введите корректный E-mail';
      }
    }
    // Если E-mail не заполнен
    else {
      errMsg.value = 'Укажите E-mail';
      isError.value = true;
      errorMsgInput.value = 'Укажите E-mail';
      store.activeErrorOperation(1500);
    }
  } catch (err) {
    console.log(err);
    errMsg.value = 'Пользователя с таким E-mail не существует';
    store.activeErrorOperation(2500);
    throw new Error(`views/AuthView: sendCodeEmail  => ${err}`);
  } finally {
    isLoading.value = false;
  }
}

// Правила ввода E-Mail
const emailRules = ref([
  (value: string) => {
    if (value.length > 0) {
      isValidEmail.value = true;
      return true;
    } else {
      isValidEmail.value = false;
      return "E-mail обязательное поле";
    }
  },
  (value: string) => {
    if (/.+@.+\..+/.test(value)) {
      isValidEmail.value = true;
      return true;
    } else {
      isValidEmail.value = false;
      return "Введите корректный E-mail";
    }
  },
]);

// // Правила ввода Пароля
const passwordRules = ref([
  (value: string) => !!value || "Пароль обязательное поле",
  (value: string) => (value && value.length >= 8) || "Минимальная длина пароля 8 символов",
]);


</script>

<style scoped>
.auth-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color-block);
}

.auth-form__container {
  width: 600px;
  height: 570px;
  box-shadow: var(--shadow);
  background-color: var(--bg-color-white);
  border-radius: 5px !important;
  overflow: hidden;
}

.auth-form__input-block {
  margin-top: 50px;
}

.auth-form__input-block+.auth-form__input-block {
  margin-top: 20px;
}

.auth-form__descr-block {
  margin-top: 2rem;
  text-wrap: wrap;
}

.auth-form__confirm-btn {
  margin: 1.5rem 1.2rem 0 0;
  align-self: flex-end;
  font-size: 13px;
  color: var(--color-default);
  border-radius: 10px;
}

.reset-form__confirm-btn {
  align-self: flex-end;
  font-size: 13px;
  color: var(--color-default);
  border-radius: 10px;
}

.input-block__subtitle {
  font-weight: 400;
  font-size: 16px;
}

.auth-form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.2rem 3.8rem;
}

.auth-form__title {
  font-size: 30px;
  color: rgba(32, 32, 32, 1);
  font-weight: 600;
  padding: 0 !important;
}

.input-block__input {
  margin-top: .3rem;
}

.auth-form__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
}

.auth-form__hint {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
}

.hint-question {
  color: var(--color-);
}

.hint-href {
  margin-left: .6rem;
  color: purple;
  cursor: pointer;
  transition: color .4s ease;
}

.hint-href:hover {
  color: rgba(128, 0, 128, 0.809);
  transition: color .4s ease;
}
</style>