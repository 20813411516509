<template>
    <v-app class="main-container">
        <drawerPanelComp v-if="isShowUserActions" :drawer-width="350" />
        <navbarComp :is-show-user-actions="isShowUserActions" /> <!-- Шапка -->
        <router-view /> <!-- Контент -->
    </v-app>
</template>

<script setup lang="ts">
import navbarComp from '@/components/navbar/navbarComp.vue';
import drawerPanelComp from '@/components/navigationDrawer/drawerPanelComp.vue';
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';

const route = useRoute();

const isShowUserActions = ref<boolean>(false);

// Рендеринг имени пользователя на навбаре относительно маршрутов требующих авторизацию в системе
watch(() => route.name, (routeName) => {
    if (routeName !== 'auth' && routeName !== 'default' && routeName !== 'notFound') {
        isShowUserActions.value = true;
    }
});

</script>

<style>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    /* Basic style */
    --shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    --border-thin: 1px solid var(--bg-color-default);
    --border-thin-light: 1px solid var(--bg-color-default-light);
    --border: 2px solid var(--bg-color-default);
    --radius: 0px;

    /* Background color */
    --bg-color-white: #FFFFFF;
    --bg-color-block: rgba(245, 245, 245, 1);
    /* --bg-color-default: #359ABA; */
    --bg-color-default: rgba(140, 107, 231, 1);
    --bg-color-second: rgba(107, 201, 231, 1);
    --bg-color-default-light: rgba(140, 107, 231, 0.412);
    --bg-color-default-light-2: rgba(140, 107, 231, 0.58);
    --bg-color-op-blue: rgba(140, 107, 231, 0.17);
    --bg-color-gray: #D9D9D9;
    --bg-color-scrollbar: rgba(140, 107, 231, 0.7);
    --bg-color-note-succ: rgba(104, 202, 80, 0.815);
    --bg-color-note-err: rgba(255, 97, 97, 0.815);

    /* Hover color */
    --hover-default: #909090;

    /* Color text */
    --color-default: rgba(140, 107, 231, 1);
    --color-note-succ: rgb(14, 104, 14);
    --color-note-err: rgb(119, 28, 28);
    --color-drawer: rgb(255, 255, 255);
    --color-dark: rgba(32, 32, 32, 1);
    --color-gray: rgb(95, 95, 95);
    --color-white: #FFFFFF;

    /* Font */
    --font: "Roboto", sans-serif !important;
}

#app {
    font-family: var(--font);
    width: 100%;
    height: 100vh;
    font-family: var(--font);
    overflow: hidden;
}

.main-container {
    display: flex;
    flex-direction: column;
}

/* По умолчанию скроллбар в приложении отключен */
::-webkit-scrollbar {
    width: 6px;
    /* ширина скроллбара */
}

::-webkit-scrollbar-thumb {
    background-color: var(--bg-color-scrollbar);
    border-radius: 20px;
    /* цвет полосы прокрутки */
}

::-webkit-scrollbar-track {
    background-color: var(--bg-color-op-blue);
    /* цвет фона */
}
</style>
